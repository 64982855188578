import React from "react";

import Layout from "../../components/layout";
import { CheckList, CheckListItem } from "../../components/CheckList";
import { CodePreview } from "../../components/docs/CodePreview";
import EmailTemplateSmallBox from "../../components/EmailTemplateSmallBox";

import loginAlertEmailImage from "../../images/email-templates/email-template-login-alert.jpg";
import loginAlertEmailImageEsai from "../../images/email-templates/email-template-login-alert-esai.jpg";
import loginAlertEmailImageRobolly from "../../images/email-templates/email-template-login-alert-robolly.jpg";
import loginAlertEmailImageElephanty from "../../images/email-templates/email-template-login-alert-elephanty.jpg";
import loginAlertEmailImageDoggify from "../../images/email-templates/email-template-login-alert-doggify.jpg";
import receiptEmailImage from "../../images/email-templates/email-template-receipt.jpg";
import organizationInviteEmailImage from "../../images/email-templates/email-template-organization-invite.jpg";
import passwordResetEmailImage from "../../images/email-templates/email-template-password-reset.jpg";
import ssoEmailImage from "../../images/email-templates/email-template-sso.jpg";
import canceledsubscriptionEmailImage from "../../images/email-templates/email-template-canceled-subscription.jpg";
import welcomeEmailImage from "../../images/email-templates/email-template-welcome.jpg";

export default () => {
	return (
		<Layout
			pageMeta={{
				title: "Login alert email template – Well-tested & Ready-to-use",
				description:
					"Send a notification whenever a new or suspicious login is detected, helping your users stay on top of account security.",
			}}>
			<div>
				<section className="aboveFold mb-120">
					<div className="container">
						<div className="templates-header">
							<div className="text-left">
								<h1>Login alert email template</h1>

								<p className="">
									Keep your users informed about any new or suspicious logins on
									their account. With Sidemail, you can use login alert email
									template and powerful email API to quickly deliver crucial
									security notifications and help your users stay safe.
								</p>

								<CheckList className="mb-30">
									<CheckListItem>Mobile-responsive</CheckListItem>
									<CheckListItem>Supports all email clients</CheckListItem>
									<CheckListItem>White-label</CheckListItem>
									<CheckListItem>Fully customizable</CheckListItem>
								</CheckList>

								<a
									className="button button-primary mb-40"
									href="https://client.sidemail.io/register">
									Get this template
								</a>
							</div>

							<img
								src={loginAlertEmailImage}
								loading="lazy"
								alt="Login alert email template"
								className="templates-image"
							/>
						</div>
					</div>
				</section>

				<section className="container maxWidth-820 text-center mb-150">
					<h2 className="title-large">Ready to send via API</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						Send emails, like this login alert email template, from your
						application in just a few minutes — Sidemail makes it incredibly
						easy to create, deliver, maintain, and analyze beautiful
						transactional emails.{" "}
						<a href="/email-sending-api/">Learn more about email sending API</a>
						.
					</p>

					<CodePreview language="javascript">
						{`
await sidemail.sendEmail({
toAddress: "user@email.com",
fromName: "Company name",
fromAddress: "your@company.com",
templateName: "Login alert",
templateProps: {
    "project_name": "Company name",
    "time": "1 January 2025, 16:28 UTC",
    "device": "Chrome on Windows",
    "location": "London, UK"
    },
});
`}
					</CodePreview>
				</section>

				<section className="container text-center mb-150">
					<h2 className="title-large">Customize design to fit your brand</h2>

					<p className="maxWidth-680 text-large text-center itemCenter mb-50">
						With Sidemail, you get a beautiful email design that you can fully
						customize to fit your brand — upload your logo, pick your brand
						colors and font, and if you fancy, turn on dark mode.
					</p>

					<div className="templatePreviewContainer-4">
						<div className="templatePreview-4">
							<img
								src={loginAlertEmailImageEsai}
								alt="Login alert email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={loginAlertEmailImageDoggify}
								alt="Login alert email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={loginAlertEmailImageRobolly}
								alt="Login alert email template image"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview-4">
							<img
								src={loginAlertEmailImageElephanty}
								alt="Login alert email template image"
								loading="lazy"
							/>
						</div>
					</div>
				</section>

				<section className="mb-140">
					<div className="container maxWidth-880">
						<div className="grid grid--alignCenter mb-30">
							<div className="grid-cell grid-cell--notFluid">
								<h2 className="m-0">Common questions</h2>
							</div>

							<div className="grid-cell">
								<hr className="separator m-0" />
							</div>
						</div>

						<p className="u-text-bold mb-4">
							Do I need to work with HTML in order to use this login alert email
							template?
						</p>
						<p className="text-light mt-0 mb-50">
							No, you don't. We provide you with email templates that are
							well-tested and ready to send, so you don't need to be familiar
							with coding HTML emails.
						</p>

						<p className="u-text-bold mb-4">
							May I customize the design of the login alert email template?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, you can fully customize the design – upload your logo, pick
							colors and fonts, and turn dark mode on or off.
						</p>

						<p className="u-text-bold mb-4">
							Is this email template mobile responsive?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, all templates, including this login alert email, are mobile
							responsive and well-tested, so they won't break in any inbox
							(including Outlook!).
						</p>

						<p className="u-text-bold mb-4">
							How long does it take to get started?
						</p>
						<p className="text-light mt-0 mb-50">
							Integrating transactional emails, like this login alert email, is
							extremely fast and straightforward with Sidemail. The whole
							integration process takes under 30 minutes.{" "}
							<a href="https://client.sidemail.io/register">
								Start your free trial here
							</a>
							.
						</p>

						<p className="u-text-bold mb-4">
							May I create my own email template from scratch?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, Sidemail features{" "}
							<a href="/no-code-email-editor/"> No-code email editor</a> that
							will help you craft professionally-looking, responsive email
							templates. You can also use Sidemail's AI email generator to craft
							the email copy.
						</p>
					</div>
				</section>

				<section className="container maxWidth-880 text-center mb-50">
					<h2 className="title-large mb-50">More email templates...</h2>

					<div className="moreTemplatesWrapper">
						<EmailTemplateSmallBox
							title="Welcome email"
							href="/transactional-email-templates/welcome-email-template/"
							src={welcomeEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Password reset email"
							href="/transactional-email-templates/password-reset-email-template/"
							src={passwordResetEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Organization invite email"
							href="/transactional-email-templates/organization-email-template/"
							src={organizationInviteEmailImage}
						/>
					</div>

					<div className="moreTemplatesWrapper mb-40">
						<EmailTemplateSmallBox
							title="Receipt email"
							href="/transactional-email-templates/receipt-email-template/"
							src={receiptEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Single sign-on email"
							href="/transactional-email-templates/sso-email-template/"
							src={ssoEmailImage}
						/>
						<EmailTemplateSmallBox
							title="Canceled subscription email"
							href="/transactional-email-templates/canceled-subscription-email-template/"
							src={canceledsubscriptionEmailImage}
						/>
					</div>

					<a href="/transactional-email-templates/">
						Browse all email templates →
					</a>
				</section>
			</div>
		</Layout>
	);
};
